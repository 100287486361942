import * as React from "react";
import { connect } from "react-redux";
import SEO from "../components/seo";
import { Button, Box } from "grommet";
import { getDownloadCsv, availableCsv } from "api/download";
import { FaFileDownload } from "react-icons/fa";
import { saveAs } from "file-saver";
import { RootState } from "../reducers";
import NotFound from "./404";

const DownloadButton = (props: { label: string; csv: availableCsv }) => {
  const { label, csv } = props;
  return (
    <Button
      label={label}
      icon={<FaFileDownload />}
      margin="small"
      onClick={() => {
        getDownloadCsv(csv).then(({ data }) => {
          const blob = new Blob([data.csv], {
            type: "text/csv;charset=utf-8",
          });
          saveAs(blob, `${data.filename}.csv`);
        });
      }}
    />
  );
};

interface Props {
  roles: string[];
}

const DashboardPage: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { roles } = props;
  if (!roles.includes("admin")) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <SEO title="Dashboard" />
      <Box align="center" margin="large">
        <h2>Download CSV</h2>
      </Box>
      <Box
        margin="large"
        direction="row"
        align="center"
        justify="center"
        wrap={true}
      >
        <DownloadButton label="organizations.csv" csv="organizations" />
        <DownloadButton label="projects.csv" csv="projects" />
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  roles: state.session.roles,
});

export default connect(mapStateToProps)(DashboardPage);
