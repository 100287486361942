import * as React from "react";
import { NotFound } from "shared-ui";
import SEO from "../components/seo";

const NotFoundPage = (): JSX.Element => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <NotFound />
  </React.Fragment>
);

export default NotFoundPage;
